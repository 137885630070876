.App {
  font-family: Arial, sans-serif;
  padding: 20px;
}

table {
  width: 100%;
  border-collapse: collapse;
}

table, th, td {
  border: 1px solid black;
}

th, td {
  padding: 8px;
  text-align: center;
}

th {
  background-color: #303030;
  color:  #ffffff;

}

body {
  background-color: #070708;
  color:  #ffffff;
}

th:hover {
  background-color: #272ebc;
}